// import { Link } from "gatsby"
import React, { useEffect } from "react"
// import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

// s4n
// import "./../../assets/sass/components/service/cookie-compliance.scss"
import $ from 'jquery';
import Cookies from 'universal-cookie';
import Link from './../Link'



const translation = {
    privacy_policy: {
        pl: {
            before: `
                W naszym Serwisie używamy plików cookies. Korzystając dalej z Serwisu, 
                wyrażasz zgodę na stosowanie plików cookies zgodnie z 
            `,
            after: `
                . 
                Wyrażenie zgody jest dobrowolne, w każdej chwili można ją cofnąć poprzez zmianę ustawień dotyczących plików „cookies” w używanej przeglądarce internetowej.
            `,
            accept: `Akceptuję`,
        },
        en: {
            before: `
                We use cookies on our Website. By continuing to use the Website, 
                you agree to the use of cookies in accordance with the 
            `,
            after: `
                . 
                Expressing consent is voluntary, you can undo it at any time by changing the settings for "cookies" in your web browser.
            `,
            accept: `Accept`,
        },
    },
}



const CookieCompliance = (props) => {
    const intl = useIntl();

    const { children, serviceId } = props

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
        componentDidMount(props);
    }, []);

    const componentDidMount = (props) => {
        const cookies = new Cookies();

        if (!cookies.get('blCookiesAccepted')) {
            const $slidingPopupCookies = $('#sliding-popup-cookies');

            $slidingPopupCookies.addClass('visible');
            $slidingPopupCookies.find('.agree-button').on('click', function() {
                $slidingPopupCookies.removeClass('visible');

                const current = new Date();
                const nextYear = new Date();
                nextYear.setFullYear(current.getFullYear() + 10); // 10 years ahead
                cookies.set('blCookiesAccepted', true, {
                    path: `/`, // path (string): cookie path, use / as the path if you want your cookie to be accessible on all pages
                    // expires: new Date(Date.now() + 2592000), // expires (Date): absolute expiration date for the cookie
                    expires: nextYear,
                    // maxAge: 5, //60 * 60 * 24 * 31, // ~ one month // maxAge (number): relative max age of the cookie from when the client receives it in second
                });
            });
        }
    }

    /**
     * @see: https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie
     */
    const agreeButtonClicked = () => {

    }

    return (
        <div id="sliding-popup-cookies" className="sliding-popup-bottom">
            <div className="eu-cookie-compliance-banner eu-cookie-compliance-banner-info">
                <div className="popup-content info eu-cookie-compliance-content">
                    <div id="popup-text" className="eu-cookie-compliance-message">
                        <p>
                            {translation.privacy_policy[intl.locale].before}
                            <Link to={33} target="_blank"></Link>
                            {translation.privacy_policy[intl.locale].after}
                        </p>
                    </div>
                    <div id="popup-buttons" className="eu-cookie-compliance-buttons">
                        <button 
                            type="button" 
                            // data-onClick={agreeButtonClicked} 
                            className="agree-button eu-cookie-compliance-agree-button">{translation.privacy_policy[intl.locale].accept}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CookieCompliance